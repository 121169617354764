@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

/* Custom CSS */
.change-text h3 .word {
  @apply absolute flex opacity-0;
}
.change-text h3 .word .letter {
  @apply origin-[center_center_25px];
}
.change-text h3 .word .letter.out {
  @apply transition-[0.2s] duration-[cubic-bezier(0.6,-0.28,0.735,0.045)];
  transform: rotateX(90deg);
}
.change-text h3 .word .letter.in {
  @apply transition-[1s] duration-[ease];
}
.change-text h3 .word .letter.behind {
  transform: rotateY(-90deg);
}
